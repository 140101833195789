import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  // Set title
  document.title = 'Boloboc';

  // Get device orientation
  const acl = new window.Accelerometer({ frequency: 100 });

  let stopReading = () => {
    acl.stop();
  }
  let startReading = () => {
    acl.start();
  }
  let toggleReading = () => {
    // Get button text
    let button = document.querySelector('button');
    // alert(isReading);
    // stopReading();


    if (button.innerHTML === 'Pause') {
      stopReading();
      button.innerHTML = 'Start';
    } else {
      startReading();
      button.innerHTML = 'Pause';
    }
  }


  let addEventListener = () => {
    acl.addEventListener("reading", handleOrientation);

    // Remove overlay from DOM
    let overlay = document.querySelector('.accessOverlay');
    overlay.remove();


    startReading();
  }

  let handleOrientation = () => {

    let degrees;
    // }
    if (acl.y == 0) {
      if (acl.x < 0) degrees = -90;
      else degrees = 90;
    } else {
      degrees = Math.atan(acl.x / acl.y) * 180 / Math.PI;
      // If acl.y < 0, then we are in the 2nd or 3rd quadrant
      if (acl.y < 0) {
        degrees += 180;
      }
    }


    // Set degrees indicator
    let degreesContainer = document.querySelector('.leveledContainer');
    degreesContainer.style.transform = `rotate(${degrees}deg)`;

    // Set degrees text
    let degreesText = document.querySelector('.degrees');

    // Show only integer part of degrees
    let degreesString = degrees.toString();
    if (degreesString.includes('.')) {
      degreesString = degreesString.split('.')[0];
    }

    degreesText.innerHTML = `${degreesString}°`;
  }
  return (
    <div className="App">
      {/* Leveled element */}
      <div className='leveledContainer'>
        <div className="leveled"></div>
        <div className="degrees">45.0°</div>
      </div>


      <button onClick={toggleReading}>Pause</button>

      {/* Overlay that requests click */}
      <div onClick={addEventListener} className="accessOverlay">
        <div className="accessOverlayText">Click to access device orientation
        </div>
      </div>

    </div>
  );
}

export default App;